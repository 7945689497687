import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GameItem } from 'src/app/apollo/models/base-models';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SwiperBaseComponent } from '../../swiper-base/swiper-base.component';

@Component({
  selector: 'app-swiper-game-categories-body',
  templateUrl: './swiper-game-categories-body.component.html',
  styleUrls: ['./swiper-game-categories-body.component.scss']
})
export class SwiperGameCategoriesBodyComponent extends SwiperBaseComponent implements OnInit {

  @Input() game: GameItem;


  constructor() { super() }


  ngOnInit(): void {

  }

  onGameClick() {
    this.onSlideClick.emit(this.game);
  }
  
}
