import { Component, Inject, LOCALE_ID } from '@angular/core';
import { GameItem } from 'src/app/apollo/models/base-models';
import { LastPlayedService } from 'src/app/services/profile/last-played.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LICENCE } from 'src/app/global.tokens';
import {zip} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {FavouritesService} from 'src/app/services/profile/favourites.service';
import {BaseGamePage} from '../ase-game-page.component';
import {MatDialog} from '@angular/material/dialog';
import {LoginStatusService} from 'src/app/services/auth/login/login-status.service';

@Component({
  selector: 'app-my-games',
  templateUrl: './my-games.component.html',
  styleUrls: ['./my-games.component.scss']
})
export class MyGamesComponent extends BaseGamePage {
  isLoggedIn = true;
  isOutsideCategory = true;
  activeTab = null;
  constructor(
    public dialog: MatDialog,
    public lastPlayedService: LastPlayedService,
    public loginStatusService: LoginStatusService,
    public favouriteService: FavouritesService,
    public detectDeviceService: DetectDeviceService,
    @Inject(LICENCE) public licence: string,
    @Inject(LOCALE_ID) public locale: string,

  ) { super(dialog); }

  lastPlayedGames: GameItem[];
  favouriteGames: GameItem[];

  ngOnInit(): void {
    if (!this.favouriteService.isRefreshed) {
      this.favouriteService.refreshFavouriteGames();
    }
    if (!this.lastPlayedService.isRefreshed) {
      this.lastPlayedService.refreshLastPlayed();
    }
    this.loginStatusService.getIfUserLogged().pipe(take(1)).subscribe((isLogged) => {
      this.isLoggedIn = true;
    });
    const favourites$ = this.favouriteService.getFavouriteGames();
    const lastPlayed$ = this.lastPlayedService.getLastPlayed();
    zip([lastPlayed$, favourites$]).pipe(takeUntil(this.unsubscribe)).subscribe(
      ([lastPlayed, favourites]) => {
        // console.log(favourites, lastPlayed, this.favouriteService.isRefreshed, this.lastPlayedService.isRefreshed);
        this.favouriteGames = favourites;
        this.lastPlayedGames = lastPlayed;
        if ((this.activeTab === null) && this.favouriteService.isRefreshed && this.lastPlayedService.isRefreshed) {
          this.activeTab = this.favouriteGames.length > 0 ? 'favourites' : 'last-played';
        }
      }
    );
  }
  switchTab(tab: string): void {
    this.activeTab = tab;
  }
}
