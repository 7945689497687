<ng-container *ngIf="swiperCategories">
    <app-swiper (onSlideClick)="onCategoryClick($event)" 
        [swiperBody]="bodyTemplate" 
        [swiperConfig]="categoriesSwiperConfig"
    > 

    <ng-template #bodyTemplate>
        <ng-container *ngFor="let category of swiperCategories">
            <app-swiper-categories-body #slide [category]="category"></app-swiper-categories-body>
        </ng-container>
    </ng-template>

    </app-swiper>
</ng-container>