import { AfterContentInit, AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { SwiperConfiguration } from '../swiper-config.model';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';


@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent extends BasePageComponent implements OnInit, AfterViewInit, AfterContentInit {

  @ViewChild('swiperWrapper') swiperWrapper!: ElementRef;

  @ContentChildren('slide') swiperSlides!: QueryList<any>;
  @ContentChildren('head') swiperHeads!: QueryList<any>;

  @Input() swiperConfig: SwiperConfiguration;
  @Input() swiperHead: TemplateRef<any>;
  @Input() swiperBody: TemplateRef<any>;

  @Output() onSlideClick = new EventEmitter<any>();
  @Output() onSwipeToFront = new EventEmitter<any>();
  @Output() onSwipeToBack = new EventEmitter<any>();

  private slideData: any;

  constructor(
    private renderer: Renderer2
  ) { super() }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.setConfig(this.swiperConfig);
  }

  ngAfterContentInit(): void {
    this.swiperSlides.changes.subscribe((newList: QueryList<any>) => {
      newList.toArray().forEach((swiperGameBody) => {
        //console.log('swiperGameBody =>', swiperGameBody);

        if (swiperGameBody.onSlideClick) {
          swiperGameBody.onSlideClick.subscribe((data: any) => {
            //console.log('swiperGameBody data', data);
            this.slideData = data;
          });
        }

      });
    });

    this.swiperHeads.changes.subscribe((newList: QueryList<any>) => {
      newList.toArray().forEach((swiperHead) => {
        //console.log('swiperHead =>', swiperHead);

        if (swiperHead.onSwipeToFront) {
          swiperHead.onSwipeToFront.subscribe((data: any) => {
            //console.log('onSwipeToFront');
            this.onSwipeToFront.emit();
          });
        }

        if (swiperHead.onSwipeToBack) {
          swiperHead.onSwipeToBack.subscribe((data: any) => {
            //console.log('onSwipeToBack');
            this.onSwipeToBack.emit();
          });
        }

      });
    });
  }

  setConfig(swiperConfig: SwiperConfiguration) {
    if (swiperConfig) {
      swiperConfig.swiperWrapper.gap ? this.renderer.setStyle(this.swiperWrapper.nativeElement, 'gap', swiperConfig.swiperWrapper.gap) : {};
    }
  };

  clickDebounceTimeout(event: any) {
    //console.log('Click debounce timeout triggered', event);
    this.setSlideClickEvent();
  }

  setSlideClickEvent() {
    this.onSlideClick.emit(this.slideData)
  };

}
