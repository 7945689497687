import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SwiperConfiguration } from '../../../swiper-config.model';
import { GameCategory } from 'src/app/apollo/models/base-models';
import { Router } from '@angular/router';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { liveCasinoTrx } from 'src/app/router-translation.labels';
import { getMappedCategoryLink } from 'src/app/router-mapping';

export const gameSwiperConfig: SwiperConfiguration = {
  swiperWrapper: {
    gap: '16px',
  },
  swiperBodyWrapper: {
    height: '250px',
    gap: '16px',
    aliginItems: 'flex-start',
  },
  
  swipeToMove: 1,
  loop: true,
  sticky: true,
}

@Component({
  selector: 'app-swiper-game-categories-template',
  templateUrl: './swiper-game-categories-template.component.html',
  styleUrls: ['./swiper-game-categories-template.component.scss']
})
export class SwiperGameCategoriesTemplateComponent extends BasePageComponent implements OnInit {

  public gameSwiperConfig = gameSwiperConfig;

  @Input() category: any;
  @Output() gameClick: EventEmitter<any> = new EventEmitter<any>();
  link:any;
  constructor(
    protected translationConfig: TranslationConfig,
    private router: Router,
  ) { super() }

  ngOnInit(): void {
    this.link = this.onCategoryClick(this.category);
  }

  onGameClick(data: any) {
    this.gameClick.emit(data);
  }

  onCategoryClick(category: GameCategory) {
    if (this.router.url.includes(this.translationConfig.getTranslation(liveCasinoTrx)) ||
      category.name === this.translationConfig.getTranslation(this.translationConfig.getTranslation(liveCasinoTrx))) {
      return [getMappedCategoryLink(category, this.translationConfig, false)];
    }
    const mapping = [getMappedCategoryLink(category, this.translationConfig)]
    return mapping;
  }

}
