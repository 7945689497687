import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedComponentsModule } from 'src/app/modules/shared-components/shared-components.module';
import { SwiperComponent } from './swiper/swiper.component';
import { SwiperBaseComponent } from './swiper/swiper-base/swiper-base.component';
import { SwiperBodyComponent } from './swiper/swiper-body/swiper-body.component';
import { SwiperGameCategoriesHeadComponent } from './swiper/swiper-head/swiper-game-categories-head/swiper-game-categories-head.component';
import { SwiperGameCategoriesBodyComponent } from './swiper/swiper-body/swiper-game-categories-body/swiper-game-categories-body.component';
import { SwiperCategoriesBodyComponent } from './swiper/swiper-body/swiper-categories-body/swiper-categories-body.component';
import { SwiperCategoriesTemplateComponent } from './swiper/swiper-template/swiper-categories-template/swiper-categories-template.component';
import { SwiperGameCategoriesTemplateComponent } from './swiper/swiper-template/swiper-game-categories-template/swiper-game-categories-template.component';
import { RouterModule } from '@angular/router';





@NgModule({
  declarations: [
    SwiperComponent,
    SwiperBaseComponent,
    SwiperBodyComponent,
    SwiperGameCategoriesHeadComponent,
    SwiperGameCategoriesBodyComponent,
    SwiperCategoriesBodyComponent,
    SwiperCategoriesTemplateComponent,
    SwiperGameCategoriesTemplateComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedComponentsModule
  ],
  exports: [
    SwiperComponent,
    SwiperBaseComponent,
    SwiperBodyComponent,
    SwiperGameCategoriesHeadComponent,
    SwiperGameCategoriesBodyComponent,
    SwiperCategoriesBodyComponent,
    SwiperCategoriesTemplateComponent,
    SwiperGameCategoriesTemplateComponent,
  ]
})
export class AppSwiperModule {
  
}