<div class="swiper-wrapper" #swiperWrapper>
    <ng-container *ngTemplateOutlet="swiperHead"/>

    <app-swiper-body (clickDebounceTimeout)="clickDebounceTimeout($event)" 
        [swiperConfig]="swiperConfig" 
        [onSwipeToFront]="onSwipeToFront" 
        [onSwipeToBack]="onSwipeToBack"
    >
        <ng-container *ngTemplateOutlet="swiperBody"/>
    </app-swiper-body>
</div>