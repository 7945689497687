import { Component, Input, OnInit } from '@angular/core';
import { SwiperBaseComponent } from '../../swiper-base/swiper-base.component';

@Component({
  selector: 'app-swiper-categories-body',
  templateUrl: './swiper-categories-body.component.html',
  styleUrls: ['./swiper-categories-body.component.scss']
})
export class SwiperCategoriesBodyComponent extends SwiperBaseComponent implements OnInit {

  @Input() category: any;

  constructor() { super() }

  ngOnInit(): void {

  }

  onCategoryClick() {
    this.onSlideClick.emit(this.category);
  }

}
