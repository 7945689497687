import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SearchGameService } from 'src/app/services/games/search-game.service';
import { SwiperConfiguration } from '../../../swiper-config.model';
import { GameCategory } from 'src/app/apollo/models/base-models';
import { FrontPageGamesService } from 'src/app/services/page/front-games.service';
import { takeUntil } from 'rxjs';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';

export const categoriesDesktopSwiperConfig: SwiperConfiguration = {
  swiperWrapper: {
    gap: '32px',
    backgroundColor: '#1B2827',
    borderRadius: '16px'
  },
  swiperBodyWrapper: {
    height: '84px',
    width: 'auto',
    gap: '11px',
    aliginItems: 'flex-start',
    //left: '-8px',
    padding: '12px'
  },
  
  swipeToMove: 1,
  loop: false,
  sticky: true,
  stretch: true,
  stretchOffsetLeft: 100,
  stretchOffsetRight: 200,
}

export const categoriesMobileSwiperConfig: SwiperConfiguration = {
  swiperWrapper: {
    gap: '32px',
    backgroundColor: '#1B2827',
  },
  swiperBodyWrapper: {
    height: '84px',
    width: 'auto',
    gap: '10px',
    aliginItems: 'flex-start',
    padding: '12px'
  },
  
  swipeToMove: 1,
  loop: false,
  sticky: true,
  stretch: true,
  stretchOffsetLeft: 100,
  stretchOffsetRight: 100,
}

@Component({
  selector: 'app-swiper-categories-template',
  templateUrl: './swiper-categories-template.component.html',
  styleUrls: ['./swiper-categories-template.component.scss']
})
export class SwiperCategoriesTemplateComponent extends BasePageComponent implements OnInit {

  public swiperCategories: GameCategory[];
  public categoriesSwiperConfig: SwiperConfiguration;

  @Output() categoryClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private detactDeviceService: DetectDeviceService,
    private searchGamesService: SearchGameService,
    private frontPageGamesService: FrontPageGamesService,
  ) { 
    super();
    
    this.categoriesSwiperConfig = detactDeviceService.isDesktop() ? categoriesDesktopSwiperConfig : categoriesMobileSwiperConfig;
  
  }

  ngOnInit(): void {
    this.frontPageGamesService.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      this.swiperCategories = response.pagesCategories[0]?.categories.filter((item) => {
        return item.displayName && item.displayName.trim() !== '';
      });

      this.swiperCategories = this.formatText(this.swiperCategories);
    });
  }

  onCategoryClick(category: any) {
    this.searchGamesService.saveSearchHistory('');
    this.searchGamesService.setSearchSwiper(category.name);

    this.categoryClick.emit();
  }

  formatText(categories: GameCategory[]) {
    return categories.map((category) => {
      const words = category.displayName.split(' ');
      let formattedDisplayName;
      if (words.length > 1) {
        formattedDisplayName = `${words[0]}<br>${words.slice(1).join(' ')}`;
      } else {
        formattedDisplayName = category.displayName;
      }
      return {
        ...category,
        formattedDisplayName
      };
    });
  }

}
